<template>
  <form method="POST" @submit.prevent="onSubmit" class="tw-space-y-6">
    <SignupError v-if="isConfirmError" :message="confirmError" />

    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="new_password"
          id="new_password"
          name="new_password"
          type="password"
          placeholder="Enter new password"
          autocomplete="new-password"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="[
            errors.includes('new_password')
              ? 'tw-border tw-border-red-500'
              : '',
          ]"
        />
      </div>
      <Password
        v-model="new_password"
        :strength-meter-only="true"
        @score="showScore"
        @feedback="showFeedback"
        class="ml-0"
      />
    </div>
    <div class="tw-space-y-1">
      <div class="tw-mt-1">
        <input
          v-model="confirm_password"
          id="confirm_password"
          name="confirm_password"
          type="password"
          placeholder="Confirm new password"
          autocomplete="confirm-password"
          class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm"
          v-bind:class="[
            errors.includes('confirm_password')
              ? 'tw-border tw-border-red-500'
              : '',
          ]"
        />
      </div>
    </div>
    <ResetPasswordErrorList :errors="password_errors"/>
    <div>
      <button
        type="submit"
        class="tw-w-full tw-flex tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white lg:tw-bg-black-500 tw-bg-orange-500 lg:hover:tw-bg-black-300 hover:tw-bg-orange-600 focus:tw-outline-none"
        v-bind:class="[isLoading ? 'disabled:opacity-50' : '']"
      >
        <span :class="[isLoading ? 'd-none' : '']">Set new password</span>
        <clip-loader
          :loading="isLoading"
          color="#f8f8f8"
          size="15px"
        ></clip-loader>
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
const Password = () => import("vue-password-strength-meter");
import LoginContainer from "@/views/Auth/LoginContainer";
import SignupError from "@/components/Auth/SignupError";
import ResetPasswordErrorList from "@/components/Auth/ResetPasswordErrorList";

export default {
  name: "ResetPasswordConfirmForm",
  components: {
    ClipLoader,
    Password,
    LoginContainer,
    SignupError,
    ResetPasswordErrorList
  },
  data() {
    return {
      new_password: "",
      confirm_password: "",
      uid: this.$route.params.uid,
      token: this.$route.params.token,
      isLoading: false,
      errors: "",
      error_msg: "",
      error_res: null,
      isConfirmError: false,
      confirmError: "",
      password_errors: []
    };
  },
  computed: {},
  methods: {
    showFeedback({ suggestions, warning }) {
      console.log("Suggestions:", suggestions);
      console.log("⚠ Warning:", warning);
    },
    showScore(score) {
      console.log("Score:", score);
    },
    validateForm() {
      this.errors = []; // clear existing errors

      // Check is not blank
      if (
        this.new_password === null ||
        this.new_password === "" ||
        this.new_password.length < 1
      ) {
        this.errors.push("new_password");
      }
      if (
        this.confirm_password === null ||
        this.confirm_password === "" ||
        this.confirm_password.length < 1
      ) {
        this.errors.push("confirm_password");
      }

      // Check passwords match
      if (
        this.new_password != this.confirm_password
      ) {
        // Passwords must match
        this.errors.push("confirm_password");
        this.isConfirmError = true;
        this.confirmError = "Passwords must match.";
      }

      if (this.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit() {
      if (this.isLoading === false && this.validateForm()) {
        this.setPassword();
      }
    },
    async setPassword() {
      this.isLoading = true
      axios
        .post(
          process.env.VUE_APP_API_ROOT + "accounts/reset_password_confirm/",
          this.$data
        )
        .then((response) => {
          this.isLoading = false
          this.$emit('success')
          // this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          // handle authentication and validation errors here
          this.error_res = error.response.data;
          this.isLoading = false
          this.new_password = ""
          this.confirm_password = ""
          this.errors = ""

          if (this.error_res.new_password) {
            this.password_errors = this.error_res.new_password;
          }
        });
    },
  },
};
</script>

<style>
.Password__strength-meter {
  margin: 0.5rem 0 0 0 !important;
}
.Password__strength-meter:after,
.Password__strength-meter:before {
  border-color: unset !important;
  border-style: unset !important;
  border-width: unset !important;
}
</style>