var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"tw-space-y-6",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.isConfirmError)?_c('SignupError',{attrs:{"message":_vm.confirmError}}):_vm._e(),_c('div',{staticClass:"tw-space-y-1"},[_c('div',{staticClass:"tw-mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_password),expression:"new_password"}],staticClass:"tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm",class:[
          _vm.errors.includes('new_password')
            ? 'tw-border tw-border-red-500'
            : '',
        ],attrs:{"id":"new_password","name":"new_password","type":"password","placeholder":"Enter new password","autocomplete":"new-password"},domProps:{"value":(_vm.new_password)},on:{"input":function($event){if($event.target.composing)return;_vm.new_password=$event.target.value}}})]),_c('Password',{staticClass:"ml-0",attrs:{"strength-meter-only":true},on:{"score":_vm.showScore,"feedback":_vm.showFeedback},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1),_c('div',{staticClass:"tw-space-y-1"},[_c('div',{staticClass:"tw-mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:tw-text-black-500 tw-text-gray-200 lg:tw-placeholder-gray-400 tw-placeholder-gray-100 focus:tw-outline-none sm:tw-text-sm",class:[
          _vm.errors.includes('confirm_password')
            ? 'tw-border tw-border-red-500'
            : '',
        ],attrs:{"id":"confirm_password","name":"confirm_password","type":"password","placeholder":"Confirm new password","autocomplete":"confirm-password"},domProps:{"value":(_vm.confirm_password)},on:{"input":function($event){if($event.target.composing)return;_vm.confirm_password=$event.target.value}}})])]),_c('ResetPasswordErrorList',{attrs:{"errors":_vm.password_errors}}),_c('div',[_c('button',{staticClass:"tw-w-full tw-flex tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white lg:tw-bg-black-500 tw-bg-orange-500 lg:hover:tw-bg-black-300 hover:tw-bg-orange-600 focus:tw-outline-none",class:[_vm.isLoading ? 'disabled:opacity-50' : ''],attrs:{"type":"submit"}},[_c('span',{class:[_vm.isLoading ? 'd-none' : '']},[_vm._v("Set new password")]),_c('clip-loader',{attrs:{"loading":_vm.isLoading,"color":"#f8f8f8","size":"15px"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }